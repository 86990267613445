<script setup lang="ts">
import { ref, computed } from "vue";
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import MultiSelect from "primevue/multiselect";
import Divider from "primevue/divider";
import { useApiStore } from "@/stores/useApiStore";
import COUNTRY_CODES from "@/assets/json/ISO3166-1.countries.json";
import Comparator from "@/components/ComparatorDesktop.vue";
import ComparatorMobile from "@/components/ComparatorMobile.vue";
import type { Country, SelectedPolicyAttributes } from "@/models/app";

const apiStore = useApiStore();

const selectedCompareCountries = ref<Country[]>([]);

const categoryMapping: Record<SelectedPolicyAttributes, string> = {
  StateOfDevelopment: "State of development",
  Approaches: "Approaches",
  Content: "Content",
  StepsTaken: "Steps taken",
};

const categoryOptions = Object.entries(categoryMapping)
  .sort()
  .map(([key, label]) => ({
    label,
    value: key,
  }));

const selectableCompareCountries = computed(() => {
  const options = apiStore.data.policies.map((item) => {
    return {
      name: COUNTRY_CODES[item.Country as keyof typeof COUNTRY_CODES],
      code: item.Country,
    };
  });
  return options.sort((a, b) => a.name.localeCompare(b.name));
});
</script>

<template>
  <div class="welcome_text">
    <StrapiBlocks :content="apiStore.data.editorialTexts.Welcome" />
    <!--    @/assets/images/aipolicy_hero_1.jpg-->
    <div class="welcome_text_image_container">
      <img class="welcome_text_image" src="@/assets/images/logo2.png" />
    </div>
  </div>
  <Divider></Divider>
  <h2 class="" style="padding-top: 8px">Choose countries</h2>
  <MultiSelect
    v-model="selectedCompareCountries"
    display="chip"
    :options="selectableCompareCountries"
    optionLabel="name"
    filter
    placeholder="Select up to 3 countries"
    :selectionLimit="3"
    class="" />

  <div v-if="selectedCompareCountries.length > 0" class="">
    <br />
    <h2 class="">View policies</h2>
    <span class="comparator-desktop">
      <Comparator
        :selectedCompareCountries="selectedCompareCountries"
        :categoryOptions="categoryOptions"></Comparator>
    </span>

    <span class="comparator-mobile">
      <ComparatorMobile
        :selectedCompareCountries="selectedCompareCountries"
        :categoryOptions="categoryOptions"></ComparatorMobile>
    </span>
  </div>
</template>
<style lang="scss">
@import "@/assets/variables.scss";
.welcome_text {
  display: flex;
}
.welcome_text p {
  max-width: 90ch;
  flex: 1;
}
.welcome_text_image_container {
  flex: 0;
  padding-left: 16px;
  padding-right: 8px;
  margin-left: auto;
}
.welcome_text_image {
  width: 180px;
  border-radius: 16px;
}
@media (max-width: $phone-breakpoint) {
  .welcome_text_image {
    width: 100px;
  }
  .welcome_text {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .welcome_text_image_container {
    margin-left: 0;
  }
}
.comparator-desktop,
.comparator-mobile {
  margin-left: -16px;
  // less jumpy accordion fx
  min-height: 500px;
}

@media (min-width: $phone-breakpoint) {
  .comparator-desktop {
    display: block;
  }
}
@media (max-width: $phone-breakpoint) {
  .comparator-desktop {
    display: none;
  }
}

@media (min-width: $phone-breakpoint) {
  .comparator-mobile {
    display: none;
  }
}
@media (max-width: $phone-breakpoint) {
  .comparator-mobile {
    display: block;
  }
}
.p-multiselect {
  min-width: 17rem;
}
.p-multiselect-label {
  display: flex;
  flex-wrap: wrap;
}
.p-multiselect-token {
  margin: 0.1rem;
}
.p-accordionheader {
  background-color: transparent !important;
}
</style>
