<script setup lang="ts">
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import { useApiStore } from "@/stores/useApiStore";
import type { SelectedPolicyAttributes } from "@/models/app";
import type { BlocksContent } from "vue-strapi-blocks-renderer";
import CountryTabBackground from "./CountryTabBackground.vue";

defineProps({
  selectedCompareCountries: Object,
  categoryOptions: Object,
});

const apiStore = useApiStore();

const getPolicyContent = (
  countryCode: string,
  category: SelectedPolicyAttributes,
): BlocksContent => {
  const policies = apiStore.data.policies.find(
    (item) => item.Country === countryCode,
  );
  if (policies && policies[category]) {
    return policies[category] as BlocksContent;
  } else {
    return [{ type: "paragraph", children: [] }];
  }
};
</script>

<template>
  <Accordion value="0">
    <AccordionPanel
      v-for="category in categoryOptions"
      :key="category.value"
      :value="category.value">
      <AccordionHeader>{{ category.label }}</AccordionHeader>
      <AccordionContent>
        <div
          v-if="selectedCompareCountries"
          :class="[
            'accordion-content-grid',
            `columns-${Math.min(selectedCompareCountries.length, 3)}`,
          ]">
          <div
            v-for="country in selectedCompareCountries"
            :key="country.code"
            class="country-card">
            <div class="accordion-content-header">
              <CountryTabBackground :countryCode="country.code" />
              <span class="country-name">{{ country.name }}</span>
            </div>
            <StrapiBlocks
              :content="
                getPolicyContent(
                  country.code, // as keyof typeof COUNTRY_CODES,
                  category.value as SelectedPolicyAttributes,
                )
              " />
          </div>
        </div>
      </AccordionContent>
    </AccordionPanel>
  </Accordion>
</template>

<style lang="scss">
@import "@/assets/variables.scss";
.accordion-content-grid {
  display: grid;
  gap: 16px; // Adjust this for spacing between grid items

  &.columns-1 {
    grid-template-columns: 1fr;
  }

  &.columns-2 {
    grid-template-columns: 1fr 1fr;
  }

  &.columns-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.country-card {
  padding: 8px;
  //max-height: 500px;
  //overflow: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.country-card p {
  //max-height: 500px; border-right: 1px solid $background-gradient-3;
  //overflow: auto;
}
.country-card:last-child {
  border-right: 0;
}
.accordion-content-header {
  display: flex;
}
.country-name {
  margin-left: 4px;
  margin-top: 4px;
}
.country-background {
  width: 40px;
  height: 40px;
  background-size: 40px;
  background-repeat: no-repeat;
}
.country-background-USA {
  background-image: url("@/assets/images/countries/US.png");
}
.country-background-UK {
  background-image: url("@/assets/images/countries/GB.png");
}
.country-background-CH {
  background-image: url("@/assets/images/countries/CH.png");
}
.country-background-US {
  background-image: url("@/assets/images/countries/US.png");
}
.country-background-GB {
  background-image: url("@/assets/images/countries/GB.png");
}
.country-background-BR {
  background-image: url("@/assets/images/countries/BR.png");
}
.country-background-BE {
  background-image: url("@/assets/images/countries/BE.png");
}
.country-background-TW {
  background-image: url("@/assets/images/countries/TW.png");
}
.country-background-AU {
  background-image: url("@/assets/images/countries/AU.png");
}
</style>
