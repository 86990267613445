<script setup lang="ts">
import { StrapiBlocks } from "vue-strapi-blocks-renderer";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import { useApiStore } from "@/stores/useApiStore";
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import CountryTabBackground from "./CountryTabBackground.vue";

import type { SelectedPolicyAttributes } from "@/models/app";
import type { BlocksContent } from "vue-strapi-blocks-renderer";

defineProps({
  selectedCompareCountries: Object,
  categoryOptions: Object,
});

const apiStore = useApiStore();

const getPolicyContent = (
  countryCode: string,
  category: SelectedPolicyAttributes,
): BlocksContent => {
  const policies = apiStore.data.policies.find(
    (item) => item.Country === countryCode,
  );
  if (policies && policies[category]) {
    return policies[category] as BlocksContent;
  } else {
    return [{ type: "paragraph", children: [] }];
  }
};
</script>

<template>
  <Tabs value="0">
    <TabList class="comparator-mobile-tablist">
      <Tab
        v-for="country in selectedCompareCountries"
        :key="country.code"
        :value="country.code">
        <CountryTabBackground :countryCode="country.code" />
        {{ country.code }}
      </Tab>
    </TabList>
    <TabPanels>
      <TabPanel
        v-for="country in selectedCompareCountries"
        :value="country.code"
        :key="country.code">
        <Accordion value="0">
          <AccordionPanel
            v-for="category in categoryOptions"
            :key="category.value"
            :value="category.value">
            <AccordionHeader>{{ category.label }}</AccordionHeader>
            <AccordionContent>
              <p>
                <StrapiBlocks
                  :content="
                    getPolicyContent(
                      country.code, // as keyof typeof COUNTRY_CODES,
                      category.value as SelectedPolicyAttributes,
                    )
                  " />
              </p>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </TabPanel>
    </TabPanels>
  </Tabs>
</template>

<style lang="scss">
.comparator-mobile-tablist {
  display: flex;
  justify-content: center;
}
</style>
