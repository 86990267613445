<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import TextShimmer from "@/components/TextShimmer.vue";
import { useApiStore } from "@/stores/useApiStore";

const apiStore = useApiStore();
const parallaxOffset = ref(0);
const BACKGROUND_ANIMATION_SPEED = 0.25; // in percent

defineProps({
  showLoadingShimmer: {
    type: Boolean,
    default: true,
  },
});

onMounted(async () => {
  try {
    await apiStore.initialLoad();
  } catch (error) {
    console.error(error);
  }
  window.addEventListener("scroll", handleScroll);
});
onUnmounted(async () => {
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
  parallaxOffset.value = window.scrollY * BACKGROUND_ANIMATION_SPEED;
};
</script>

<template>
  <div
    class="home-parallax-background"
    :style="{ 'background-position-y': `${parallaxOffset}px` }" />

  <PageHeader />

  <div class="content">
    <div class="content-container">
      <div v-if="apiStore.data.state === 'loading' && showLoadingShimmer">
        <TextShimmer />
      </div>
      <div v-else-if="apiStore.data.state === 'ready'">
        <slot></slot>
      </div>
      <div v-else-if="apiStore.data.state === 'error'">
        <p class="text-error">
          An unknown error has occured. Please try again later.
        </p>
      </div>
    </div>
  </div>

  <PageFooter />
</template>

<style lang="scss">
@import "@/assets/variables.scss";

.content {
  flex-grow: 1;
  max-width: $max-width;
  padding: 24px;
}
@media (min-width: $max-width) {
  .content {
    min-width: calc($max-width - 24px);
    margin: 0 auto;
  }
}
// in admin allow wider max-width
@media (min-width: 1400px) {
  .content-admin {
    min-width: 1400px;
    max-width: 1400px;
  }
}

.content-container {
  //color: white;
  // #81C4F8  #4383BB
  // background: linear-gradient(135deg, #4385bc, white);

  // 8px: because of -16px in accordion
  padding: 24px 8px 24px 24px;
  background-color: rgba(
    255,
    255,
    255,
    1
  ); //rgba(255, 255, 255, 1); //RGB(249, 251, 255, 0.9);
  color: black;
  border: 1px solid transparent;
  border-width: 0 1px 1px 1px;
  border-radius: 16px;
  border: 1px solid $container-divider-color;
  box-shadow: $box-shadow;
  // prevent layout shifting
  min-height: calc(100vh - 250px);
}
@media (max-width: $phone-breakpoint) {
  .content-container {
    padding: 24px;
  }
}
.text-error {
  text-align: center;
  @include medium-normal;
  color: $text-color;
  color: $highlight-color;
}

/*
nice text effect 
    color: white;
    text-shadow: 12 12 beige;
   
    text-shadow: 1px 1px 2px #3E7DB1;
 
    -webkit-text-stroke: 1px #3E7DB1;
    */
</style>
