// manages all data fetching for the frontend

import { ref } from "vue";
import { defineStore } from "pinia";

import axios from "axios";
import type {
  StrapiResult,
  StrapiResultSingle,
  Policy,
  StrapiPolicy,
  StrapiEditorialTexts,
  EditorialTexts,
} from "@/models/strapi";
import { ENDPOINTS } from "@/services/ApiEndpoints";
import { getBaseStrapiUrl } from "@/services/Helpers";

const BASE_URL = getBaseStrapiUrl();
const MIN_WAIT_TIME = 900; // in ms

type ApiData = {
  policies: Policy[];
  state: "loading" | "ready" | "error";
  editorialTexts: EditorialTexts;
};

export const useApiStore = defineStore("api", () => {
  const data = ref<ApiData>({
    state: "loading",
    policies: [],
    editorialTexts: { id: 0, Welcome: [], About: [] },
  });

  // Public methods
  // initialLoad: loads all strapi data for the entire application
  const initialLoad = async () => {
    if (data.value.state === "ready") {
      return false;
    }
    const minWait = new Promise((resolve) =>
      setTimeout(resolve, MIN_WAIT_TIME),
    );
    try {
      await Promise.all([fetchAllPolicies(), fetchEditorialTexts(), minWait]);
      data.value.state = "ready";
    } catch (error) {
      console.error("Failed during initial load:", error);
      data.value.state = "error";
      throw Error;
    }
  };

  // Private methods
  const fetchAllPolicies = async () => {
    try {
      const response = await axios.get<StrapiResult<StrapiPolicy>>(
        `${BASE_URL}${ENDPOINTS.policy}`,
      );

      data.value.policies = response.data.data.map((item) => {
        const parsedItem = {
          id: item.id,
          ...item.attributes,
        };

        return parsedItem;
      });
    } catch (error) {
      console.error("Failed to fetch policies:", error);
      throw Error;
    }
  };

  const fetchEditorialTexts = async () => {
    try {
      const response = await axios.get<
        StrapiResultSingle<StrapiEditorialTexts>
      >(`${BASE_URL}${ENDPOINTS.editorialTexts}`);

      data.value.editorialTexts.Welcome = response.data.data.attributes.Welcome;
      data.value.editorialTexts.About = response.data.data.attributes.About;

      console.log(" data.value.editorialTexts", data.value.editorialTexts);
    } catch (error) {
      console.error("Failed to fetch editorial texts:", error);
      throw Error;
    }
  };

  return {
    data,
    initialLoad,
  };
});
